import React from "react";

import "./quote.scss";

const Quote = ({ msg, from }) => {
	return (
		<div className="quote">
			<div className="quote-icon"></div>
			<div className="quote-text">{msg}</div>
			<hr />
			<div className="quote-from">{from}</div>
		</div>
	);
};

export default Quote;
