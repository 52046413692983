import React from 'react';
import './leaderCard.scss';

const LeaderCard = ({ img, name, role }) => {
	return (
		<div className='leaderCard'>
			<img src={img} alt={name} />
			<div className='leaderCard-title'>{name}</div>
			<span className='leadershipCopy'>{role}</span>
		</div>
	);
};
export default LeaderCard;
