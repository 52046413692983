import React from 'react';
import Layout from '../components/dfa-theme/layout';
import ContentBlock from '../components/content-block';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Quote from '../components/quote';
import LeaderCard from '../components/leaderCard';
import { CONSTANTS } from '../utils/constants';
import BadgeComponent from '../components/badge-component';

import joshua from '../images/2024_JoshB.webp';
import paul from '../images/2024_PaulB.webp';
import frank from '../images/2024_FrankB.webp';
import sarah from '../images/2024_SarahM.webp';
import juan from '../images/2.0_Juan-V.webp';
import elizabeth from '../images/2.0_Elizabeth-T.webp';
import robert from '../images/2.0_Bob-C.webp';
import greg from '../images/2.0_Greg-M.webp';
import steph from '../images/2024_StephK.webp';
import pat from '../images/2024_PatL.webp';
import matt from '../images/2024_MattT.webp';
import erin from '../images/2.0_Erin-G.webp';
import chris from '../images/2.0_Chris-B.webp';
import phil from '../images/2024_DrPhill.webp';
import samC from '../images/2024_SamC.webp';
import amanda from '../images/2024_AmandaS.webp';
import lissette from '../images/2024_LisetteS.webp';
import pam from '../images/DFA_Pam-L.webp';
import amandaJ from '../images/2024_AmandaJ.webp';
import darby from '../images/2024_DarbyP.webp';
import eric from '../images/2024_Eric.webp';
import billV from '../images/2024_BillV.webp';
import dom from '../images/Final_DFA_Headshots_2024_Associate.png';
import trevor from '../images/Final_DFA_Headshots_2024_Trevor.png';
import bob from '../images/Final_DFA_Headshots_2024_Bob.png';
import guy from '../images/Final_DFA_Headshots_2024_Guy.png';

import '../scss/pages/leadership.scss';

const Leadership = () => {
	const metaTags = {
		name: 'Leadership',
		description: 'Deerfield Leadership',
		keywords: 'Deerfield, Leadership',
	};

	return (
		<Layout meta={metaTags}>
			<div className='leadership-hero-container block'>
				<ContentBlock
					outerBgColor={CONSTANTS.BROWN}
					className='solutions block'
				>
					<Grid>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col xs={10} md={8} className='leadership-content title-block'>
								<div className='leadership-copy'>
									<p className='header-orange'>leadership</p>
									<h1>Humans at heart, leaders in the office</h1>
									<p className='header-copy'>
										Innovation attracts excellence. That's why our team is made
										up of industry leaders. It's why our clients are
										trailblazing pharmaceutical brands. And it's why, together,
										Deerfield and our clients are elevating the standard of
										healthcare &ndash; one partnership at a time.
									</p>
								</div>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>
			</div>

			<div className='leadership-subnav block'>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
					<Grid>
						<Row>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={frank}
									name={'Frank Burrell'}
									role={'Chief Executive Officer'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={joshua}
									name={'Joshua Benson'}
									role={'Chief Operating Officer'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={lissette}
									name={'Lissette Steele'}
									role={'Chief Insights Officer'}
								/>
							</Col>
						</Row>
						<Row>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={amanda}
									name={'Amanda Sellers'}
									role={'President, Global Communications'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={trevor}
									name={'Trevor Fusaro'}
									role={'Chief Strategy Officer'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={dom}
									name={'Dominic Viola'}
									role={'President, Integrated Marketing'}
								/>
							</Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className=''>
					<Grid>
						<Row>
							<Col xs={1} md={1}></Col>
							<Col xs={10} md={10}>
								<Quote
									msg={`The team at Deerfield are my partners and teammates. For every project, they are forward thinking, looking for solutions, better options, and ways to improve the business.”`}
									from={`Client partner`}
								/>
							</Col>
							<Col xs={1} md={1}></Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className=''>
					<Grid>
						<Row>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={elizabeth}
									name={'Elizabeth Trachte'}
									role={'Chief Financial Officer'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={juan}
									name={'Juan Vasquez'}
									role={'Chief Technology Officer'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={samC}
									name={'Sam Cannizzaro'}
									role={'Chief Creative Officer'}
								/>
							</Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className=''>
					<Grid>
						<Row>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={robert}
									name={'Robert Caprara'}
									role={'Chief Data Scientist'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={sarah}
									name={'Sarah Marchetti'}
									role={'Executive Vice President, Human Resources'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={eric}
									name={'Eric Steckelman'}
									role={'Executive Vice President, Business Development'}
								/>
							</Col>
						</Row>
						<Row>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={matt}
									name={'Matthew Tedesco'}
									role={'Executive Vice President, Integrated Marketing'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={greg}
									name={'Greg Marshall'}
									role={'Executive Vice President, Head of Performance Marketing'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={steph}
									name={'Stephanie Keating'}
									role={'Executive Vice President, Head of Client Operations'}
								/>
							</Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className=''>
					<Grid>
						<Row>
							<Col xs={1} md={1}></Col>
							<Col xs={10} md={10}>
								<Quote
									msg={`I'm grateful and appreciative of our partners. Deerfield has consistently gone above and beyond for us. For that, I thank you, and your reputation in my view is among the best that I've worked with in my career.”`}
									from={`Client partner`}
								/>
							</Col>
							<Col xs={1} md={1}></Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className=''>
					<Grid>
						<Row>

							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={bob}
									name={'Bob Josefsberg'}
									role={'Executive Vice President, Client Services'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={guy}
									name={'Guy Varady'}
									role={'Executive Vice President, Integrated Marketing'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={billV}
									name={'Bill Veltre'}
									role={'Executive Vice President, Head of Media'}
								/>
							</Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
					<Grid>
						<Row>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={pam}
									name={'Pam Lippincott'}
									role={'Executive Vice President, Communications and Learning and Development'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={erin}
									name={'Erin Gronczewski'}
									role={'Editor-in-Chief'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={pat}
									name={'Patrick Lowery'}
									role={'Senior Vice President, Creative Strategy'}
								/>
							</Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
					<Grid>
						<Row>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={darby}
									name={'Darby Pearson'}
									role={'Managing Director, Head of Client Services'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={phil}
									name={'Philippe de Kerillis, DO, FACOEP'}
									role={'Medical Director'}
								/>
							</Col>
							<Col xs={12} md={4} className='leadershipBlock'>
								<LeaderCard
									img={amandaJ}
									name={'Amanda Johnson'}
									role={'Senior Vice President, Science'}
								/>
							</Col>
						</Row>
					</Grid>
				</ContentBlock>

			</div>
			<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
				<Grid>
					<Row>
						<Col xs={12} md={6}>
							<h2 className='boardTitle'>Board of Directors</h2>
						</Col>
						<Col xs={12} md={6}>
							<ul className='directorList'>
								<li>
									<span className='directorName'>Paul Brown</span>
									<span className='directorTitle'>Board Member and Founding Partner</span>
								</li>
								<li>
									<span className='directorName'>Rick Riegel</span>
									<span className='directorTitle'>Chairman of the Board</span>
								</li>
								<li>
									<span className='directorName'>Jeff Klein</span>
									<span className='directorTitle'>Board Member</span>
								</li>
								<li>
									<span className='directorName'>Scott Brown</span>
									<span className='directorTitle'>Board Member</span>
								</li>
								<li>
									<span className='directorName'>Barrett Lynner</span>
									<span className='directorTitle'>Board Member</span>
								</li>
							</ul>
						</Col>
					</Row>
				</Grid>
			</ContentBlock>
			<ContentBlock outerBgColor={CONSTANTS.BLUE} className='block block-blue'>
				<Grid>
					<Row>
						<Col xs={12} md={5} className='callout column2'>
							<h2>Leverage our experts</h2>
							<p>
								Our expertise always has a purpose. See how we can help your
								brand today.
							</p>
							<a
								className='dfa-button-orange'
								href='/solutions'
								aria-label='find a solution at Deerfield.'
							>
								find a solution{' '}
							</a>
						</Col>
						<Col xs={0} md={2} className='divider-holder'>
							<div className='divider'></div>
						</Col>
						<Col xs={12} md={5} className='callout column2'>
							<h2>Become an expert </h2>
							<p>
								What better way to become the best than by working with us? Join
								our team today.
							</p>
							<a
								className='dfa-button-orange'
								href='/careers'
								aria-label='more about Deerfield.'
							>
								work with us{' '}
							</a>
						</Col>
					</Row>
				</Grid>
			</ContentBlock>
			<BadgeComponent isVisible={true} />
		</Layout>
	);
};
export default Leadership;
